import Vue from 'vue';
import VueRouter from 'vue-router';
import Landing from './views/Landing.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import store from './store';

import LoginPage from './adminPanel/views/LoginPage.vue';

import LandingAdmin from './adminPanel/views/Landing.vue';
import MainNavbarAdmin from './adminPanel/layout/MainNavbar.vue';
import MainFooterAdmin from './adminPanel/layout/MainFooter.vue';

Vue.use(VueRouter);
let router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Landing',
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/admin',
      name: 'AdminPanel',
      components: { default: LandingAdmin, header: MainNavbarAdmin, footer: MainFooterAdmin },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
      meta: { requiresLogin: true }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  const access = store.state.access != null //store with namespaced  modules
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (access) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})


export default router
