<template>
  <div class="main main-raised">
    <div class="section" id="courses">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
          >
            <div
              v-for="(title, index) in courses.titles"
              :key="index"
            >
              <h2 
                class="title text-center"
                @click.stop="$emit('coursesClick', [{
                  element: title,
                  parent: courses.titles,
                  path: '/body/courses/titles'
                }])"
              >
                {{ title.text }}
              </h2>
            </div>
          </div>
        </div>
        <div class="features text-center">
          <div
            class="md-layout course-mg-bottom"
            v-for="(course, index1) in courses.data"
            :key="index1"
          >
            <div
              class="md-layout-item md-size-50 md-small-size-100"
              v-if="courseLeft(index1, 0)"
            >
              <h4 
                @click.stop="$emit('coursesClick', [{
                  element: course.title.title,
                  parent: courses.data[index1].title,
                  path: `/body/courses/data[${index1}]/title`
                }])"
                class="info-title"
              >
                {{ course.title.title.text }}
              </h4>
              <h4 
                @click.stop="$emit('coursesClick', [{
                  element: course.ages.ages,
                  parent: courses.data[index1].ages,
                  path: `/body/courses/data[${index1}]/ages`
                }])"
                class="info-title"
              >
                {{ course.ages.ages.text }}
              </h4>
              <hr />
              <div class="md-layout">
                <div 
                  class="md-layout-item text-align-left md-small-size-100" 
                  v-for="info in course.info" 
                  :key="info.name" 
                  :class="info.size"
                  @click.stop="$emit('coursesClick', [{
                    element: info,
                    parent: course.info,
                    path: `/body/courses/data[${index1}]/info`
                  }])"
                >
                  <span class="info-title info-text">
                    {{info.name}}
                  </span> 
                  <span class="info-text info-title">
                    {{info.description}}
                  </span>
                </div>
                <div v-if="course.lastinfo" class="md-layout-item md-size-100 text-align-left">
                  <span class="info-title info-text">{{course.lastinfo.name}}</span> <span class="info-title info-text">{{course.lastinfo.description}}</span>
                </div>
              </div>
              <hr />
              <p 
                class="course-description"
                @click.stop="$emit('coursesClick', [{
                  element: course.description.description,
                  parent: course.description,
                  path: `/body/courses/data[${index1}]/description`
                }])"
              >
                <span class="info-title">Описание: </span>
                  {{course.description.description.text}}
              </p>
              <md-button 
                v-if="!laptop"
                class="md-success" 
                @click="$emit('subscribeOpen', course)">
                {{course.buttonText}}
              </md-button>
              <md-button 
                v-if="!laptop && course.buttonProgram !== ''"
                class="md-success" 
                :href="course.buttonProgram"
                target="_blank">
                Программа занятий
              </md-button>
            </div>
            <div 
              :id="course.link"  
              @click.stop="$emit('coursesClick', [
                {
                  element: course.images.images[0],
                  parent: course.images.images,
                  path: `/body/courses/data[${index1}]/images/images`
                },
                {
                  element: course.videos.videos[0],
                  parent: course.videos.videos,
                  path: `/body/courses/data[${index1}]/videos/videos`
                },
              ])"
              class="md-layout-item md-size-50 md-small-size-100"
            >
              <md-card>
                <carousel
                  :per-page="1"
                  loop
                  :speed="700"
                  :autoplay-timeout="course.time"
                  :mouse-drag="true"
                  navigationEnabled
                  navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
                  navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
                >
                  <slide
                    v-for="(video, index3) in course.videos.videos"
                    :key="index3+10"
                    v-if="video.helpers.show"
                    class="parent-cover"
                  >
                    <template>
                      <LazyYoutubeVideo :src="video.link" />
                    </template>
                  </slide>
                  <slide
                    v-for="(img, index2) in course.images.images"
                    :key="index2"
                    v-if="img.helpers.show"
                    class="parent-cover"
                  >
                    <img :src="img.link" :alt="`${course.title} ${index2}`" class="cover" />
                  </slide>
                </carousel>
              </md-card>
              <br />
              <br />
              <md-button 
                v-if="laptop"
                class="md-success" 
                @click.stop="$emit('coursesClick', [{
                  element: course.buttonText.buttonText,
                  parent: course.buttonText,
                  path: `/body/courses/data[${index1}]/buttonText`
                }])"
              >
                {{course.buttonText.buttonText.text}}
              </md-button>
              <md-button 
                v-if="laptop && course.buttonProgram !== ''"
                class="md-success m-left" 
                :href="course.buttonProgram"
                target="_blank">
                Программа занятий
              </md-button>
            </div>
            <!-- -->
            <div
              class="md-layout-item md-size-50 md-small-size-100"
              v-if="courseLeft(index1, 1)"
            >
              <h4 
                @click.stop="$emit('coursesClick', [{
                  element: course.title.title,
                  parent: courses.data[index1].title,
                  path: `/body/courses/data[${index1}]/title`
                }])"
                class="info-title"
              >
                {{ course.title.title.text }}
              </h4>
              <h4 
                @click.stop="$emit('coursesClick', [{
                  element: course.ages.ages,
                  parent: courses.data[index1].ages,
                  path: `/body/courses/data[${index1}]/ages`
                }])"
                class="info-title"
              >
                {{ course.ages.ages.text }}
              </h4>
              <hr />
              <div class="md-layout">
                <div 
                  class="md-layout-item text-align-left md-small-size-100" 
                  v-for="info in course.info" 
                  :key="info.name" 
                  :class="info.size"
                  @click.stop="$emit('coursesClick', [{
                    element: info,
                    parent: course.info,
                    path: `/body/courses/data[${index1}]/info`
                  }])"
                >
                  <span class="info-title info-text">
                    {{info.name}}
                  </span> 
                  <span class="info-text info-title">
                    {{info.description}}
                  </span>
                </div>
                <div v-if="course.lastinfo" class="md-layout-item md-size-100 text-align-left">
                  <span class="info-title info-text">{{course.lastinfo.name}}</span> <span class="info-title info-text">{{course.lastinfo.description}}</span>
                </div>
              </div>
              <hr />
              <p 
                class="course-description"
                @click.stop="$emit('coursesClick', [{
                  element: course.description.description,
                  parent: course.description,
                  path: `/body/courses/data[${index1}]/description`
                }])"
              >
                <span class="info-title">Описание: </span>
                  {{course.description.description.text}}
              </p>
              <md-button 
                v-if="!laptop"
                class="md-success" 
                @click="$emit('subscribeOpen', course)">
                {{course.buttonText}}
              </md-button>
              <md-button 
                v-if="!laptop && course.buttonProgram !== ''"
                class="md-success" 
                :href="course.buttonProgram"
                target="_blank">
                Программа занятий
              </md-button>
            </div>
            <div class="md-layout">
              <!---->
              <div
                class="md-layout-item md-size-33 md-small-size-80 course-m-bottom login-cart-center"
                v-for="(additionally, additionallyIndex) in course.additionally"
                :key="additionally.name.text"
                @click.stop="$emit('coursesClick', [{
                  element: additionally,
                  parent:  course.additionally[additionallyIndex],
                  path: `/body/courses/data[${index1}]/additionally[${additionallyIndex}]`
                }])"
              >
                <login-card 
                  header-color="green">
                  <h4 slot="title" class="price-title">
                    {{ additionally.name.text }}
                  </h4>
                  <template slot="inputs">
                    <h4>
                      {{ additionally.price.text }}
                    </h4>
                  </template>
                  <md-button
                    slot="footer"
                    class="md-simple md-success md-lg"
                    @click="$emit('openAdditionally', additionally)"
                  >
                    Подробнее
                  </md-button>
                </login-card>
              </div>
              <!---->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
export default {
  components: {
    LoginCard
  },
  props: ['courses', 'laptop'],
  methods: {
    courseLeft(index, num) {
      if (index % 2 == 0 && window.innerWidth > 1000) {
        if (num == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (num == 1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
}
</script>
<style scoped>
.text-align-left {
  text-align: left;
}
.info-text {
  font-size: 0.86em;
}
.price-title {
  color: #fafafa;
  font-weight: 500;
}
.course-m-bottom {
  margin-top: 14vh;
}
.m-left {
  margin-left: 5% !important;
}
.login-cart-center {
  margin: 100px auto;
}
.course-description {
  font-size: 0.9em;
  text-align: left !important;
}
.parent-cover {
  max-height: 30%;
}
.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.course-mg-bottom {
  margin-top: 10vh;
}
.additionally-description {
  text-indent: 30px;
  font-weight: 400;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>