<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://www.instagram.com/club_genry/" target="_blank">
              Instagram
            </a>
          </li>
          <li>
            <a href="mailto:club-genry@mail.ru">
              Электронная почта
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=79185580172"
              target="_blank"
            >
              WhatsApp
            </a>
          </li>
        </ul>
      </nav>
      <!--
      <div class="copyright">
        <a href="https://www.creative-tim.com/license">
          Политика конфиденциальности
        </a>
      </div>-->
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
