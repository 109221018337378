var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised z-index-up"},[_c('div',{staticClass:"section",attrs:{"id":"about-us"}},[_c('div',{staticClass:"container text-center"},[_vm._l((_vm.sectionData.titles),function(title){return _c('div',{key:title.text},[_c('h2',{staticClass:"title",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('aboutUsClick', [{
            element: title,
            parent: _vm.sectionData.titles,
            path: '/body/aboutUs/titles'
          }])}}},[_vm._v(" "+_vm._s(title.text)+" ")])])}),_vm._l((_vm.sectionData.timeLongs),function(timeLong){return _c('div',{key:timeLong.text},[_c('h5',{staticClass:"description",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('aboutUsClick', [{
            element: timeLong,
            parent: _vm.sectionData.timeLongs,
            path: '/body/aboutUs/timeLongs'
          }])}}},[_vm._v(_vm._s(timeLong.text))])])}),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-70 text-formating"},_vm._l((_vm.sectionData.texts),function(text,index){return _c('h4',{key:index,domProps:{"innerHTML":_vm._s(text.text)},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('aboutUsClick', [{
              element: text,
              parent: _vm.sectionData.texts,
              path: '/body/aboutUs/texts'
            }])}}})}),0),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-30",on:{"click":function($event){return _vm.$emit('aboutUsClick', [
            {
              element: _vm.sectionData.sectionImage.headimage,
              parent: _vm.sectionData.sectionImage,
              path: '/body/aboutUs/sectionImage/headimage/link'
            }
          ])}}},[_c('img',{staticClass:"max-size",attrs:{"src":_vm.sectionData.sectionImage.headimage.link,"alt":"No Image"}})])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }