<template>
  <div class="main main-raised z-index-up">
    <div class="section" id="about-us">
      <div class="container text-center">
        <div v-for="title in sectionData.titles" :key="title.text">
          <h2 
            class="title"
            @click.stop="$emit('aboutUsClick', [{
              element: title,
              parent: sectionData.titles,
              path: '/body/aboutUs/titles'
            }])"
          >
            {{ title.text }}
            </h2>
        </div>
        <div v-for="timeLong in sectionData.timeLongs" :key="timeLong.text">
          <h5 
            class="description"
            @click.stop="$emit('aboutUsClick', [{
              element: timeLong,
              parent: sectionData.timeLongs,
              path: '/body/aboutUs/timeLongs'
            }])"
          >{{ timeLong.text }}</h5>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-70 text-formating">
            <h4
              v-for="(text, index) in sectionData.texts"
              :key="index"
              v-html="text.text"
              @click.stop="$emit('aboutUsClick', [{
                element: text,
                parent: sectionData.texts,
                path: '/body/aboutUs/texts'
              }])"
            >
            </h4>
          </div>
          <div 
            class="md-layout-item md-small-size-100 md-size-30"
            @click="$emit('aboutUsClick', [
              {
                element: sectionData.sectionImage.headimage,
                parent: sectionData.sectionImage,
                path: '/body/aboutUs/sectionImage/headimage/link'
              }
            ])"
          >
            <img 
              :src="sectionData.sectionImage.headimage.link" 
              alt="No Image"
              class="max-size"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['sectionData']
}
</script>
<style>
.section > .container > .title {
  margin-bottom: 0 !important;
}
.text-bold {
  font-weight: 700;
}
.padding-off {
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0px !important;
}
.z-index-up {
  z-index: 100 !important;
}
.mt-150 {
  margin-top: 30px;
}
.max-size {
  width: 100%;
}
.text-formating {
  text-align: left;
}
.text-formating > h4 {
  font-size: 1.1rem;
  text-indent: 40px;
}
</style>