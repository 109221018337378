var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-xsmall-size-100"},_vm._l((_vm.sectionData.titles),function(title){return _c('div',{key:title.text},[_c('h2',{staticClass:"title text-center",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('chooseUsClick', [{
                element: title,
                parent: _vm.sectionData.titles,
                path: '/body/chooseUs/titles'
              }])}}},[_vm._v(" "+_vm._s(title.text)+" ")])])}),0),_c('div',{staticClass:"md-layout-item md-size-100 md-xsmall-size-100 md-layout"},_vm._l((_vm.sectionData.uppers),function(upper){return _c('div',{key:upper.text,staticClass:"md-layout-item md-size-33 md-xsmall-size-100 text-center upper",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('chooseUsClick', [{
              element: upper,
              parent: _vm.sectionData.uppers,
              path: '/body/chooseUs/uppers'
            }])}}},[_c('i',{class:upper.icon}),_c('h3',{staticClass:"upper-name",domProps:{"innerHTML":_vm._s(upper.name)}}),_c('p',{staticClass:"upper-text",domProps:{"innerHTML":_vm._s(upper.text)}})])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }