<template>
  <div class="main main-raised">
    <div class="section" id="courses">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
          >
            <div
              v-for="(title, index) in courses.titles"
              :key="index"
            >
              <h2 
                class="title text-center green-text"
              >
                {{ title.text }}
              </h2>
              <h4
                class="title text-center s-text"
              >
                Групповые и индивидуальные занятия по робототехнике
              </h4>
            </div>
          </div>
        </div>
        <div class="features text-center">
          <div
            class="md-layout course-mg-bottom"
            v-for="(course, index1) in courses.data"
            :key="index1"
          >
            <div
              class="md-layout-item md-size-50 md-small-size-100"
              v-if="courseLeft(index1, 0)"
            >
              <h4 class="info-title">{{ course.title.title.text }}</h4>
              <h4 class="info-title green-text">{{ course.ages.ages.text }}</h4>
              <hr />
              <div class="md-layout">
                <div class="md-layout-item text-align-left md-small-size-100 md-size-100" v-for="info in course.info" :key="info.name">
                  <span class="info-title info-text">{{info.name}}</span> <span class="info-text">{{info.description}}</span>
                </div>
                <div v-if="course.lastinfo" class="md-layout-item md-size-100 text-align-left">
                  <span class="info-text">{{course.lastinfo.name}}</span> <span class="info-text">{{course.lastinfo.description}}</span>
                </div>
              </div>
              <hr />
              <p class="course-description" v-if="course.direction"><span class="info-title green-text">На курсе изучаем: </span>{{course.direction.direction.text}}</p>
              <p class="course-description" v-if="course.robotics"><span class="info-title green-text">По робототехнике: </span>{{course.robotics.robotics.text}}</p>
              <p class="course-description" v-if="course.program"><span class="info-title green-text">По программированию: </span>{{course.program.program.text}}</p>
              <p class="course-description" v-if="course.description"><span class="info-title green-text">Развитие soft skills навыков: </span>{{course.description.description.text}} <span class="info-title green-text">Рекомендуем начинать с пробного занятия</span></p>
              <md-button
                v-if="!laptop"
                class="md-success"
                @click="$root.$emit('subscribeOpen', course)">
                {{course.buttonText.buttonText.text}}
              </md-button>
              <md-button
                v-if="!laptop && course.buttonProgram.link !== ''"
                class="md-success"
                :href="course.buttonProgram.link"
                target="_blank">
                {{ course.buttonProgram.text }}
              </md-button>
            </div>
            <div :id="course.link" class="md-layout-item md-size-50 md-small-size-100 course-parent">
              <md-card>
                <carousel
                  :per-page="1"
                  loop
                  :speed="700"
                  :autoplay-timeout="course.time"
                  :mouse-drag="true"
                  navigationEnabled
                  navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
                  navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
                >
                  <slide
                    v-for="(video, index3) in course.videos.videos"
                    :key="index3+10"
                    v-if="video.helpers.show"
                    class="parent-cover"
                  >
                    <template>
                      <LazyYoutubeVideo :src="video.link" />
                    </template>
                  </slide>
                  <slide
                    v-for="(img, index2) in course.images.images"
                    :key="index2"
                    v-if="img.helpers.show"
                    class="parent-cover"
                  >
                    <img :src="img.link" :alt="`${course.title} ${index2}`" class="cover" />
                  </slide>
                </carousel>
              </md-card>
              <br />
              <br />
              <div class="course-button">
                <SVButton v-if="laptop" @clicked="$root.$emit('subscribeOpen', course)" :text="course.buttonText.buttonText.text" color="green"></SVButton>
              </div>
              <md-button
                v-if="laptop && course.buttonProgram.link !== ''"
                class="md-success m-left"
                :href="course.buttonProgram.link"
                target="_blank">
                {{ course.buttonProgram.text }}
              </md-button>
            </div>
            <div
              class="md-layout-item md-size-50 md-small-size-100"
              v-if="courseLeft(index1, 1)"
            >
              <h4 class="info-title">{{ course.title.title.text }}</h4>
              <h4 class="info-title green-text">{{ course.ages.ages.text }}</h4>
              <hr />
              <div class="md-layout">
                <div class="md-layout-item text-align-left md-small-size-100 md-size-100" v-for="info in course.info" :key="info.name">
                  <span class="info-title info-text">{{info.name}}</span> <span class="info-text">{{info.description}}</span>
                </div>
                <div v-if="course.lastinfo" class="md-layout-item md-size-100 text-align-left">
                  <span class="info-text">{{course.lastinfo.name}}</span> <span class="info-text">{{course.lastinfo.description}}</span>
                </div>
              </div>
              <hr />
              <p class="course-description" v-if="course.direction"><span class="info-title green-text">На курсе изучаем: </span>{{course.direction.direction.text}}</p>
              <p class="course-description" v-if="course.robotics"><span class="info-title green-text">По робототехнике: </span>{{course.robotics.robotics.text}}</p>
              <p class="course-description" v-if="course.program"><span class="info-title green-text">По программированию: </span>{{course.program.program.text}}</p>
              <p class="course-description" v-if="course.description"><span class="info-title green-text">Развитие soft skills навыков: </span>{{course.description.description.text}} <span class="info-title green-text">Рекомендуем начинать с пробного занятия</span></p>
              <div class="course-button">
                <SVButton v-if="!laptop" @clicked="$root.$emit('subscribeOpen', course)" :text="course.buttonText.buttonText.text" color="green"></SVButton>
              </div>
              <md-button
                v-if="!laptop && course.buttonProgram.link !== ''"
                class="md-success"
                :href="course.buttonProgram.link"
                target="_blank">
                {{ course.buttonProgram.text }}
              </md-button>
            </div>
            <div class="md-layout">
              <!---->
              <div
                class="md-layout-item md-size-33 md-small-size-80 course-m-bottom login-cart-center"
                v-for="additionally in course.additionally"
                :key="additionally.name.text"
              >
                <login-card header-color="green">
                  <h4 slot="title" class="price-title">
                    {{ additionally.name.text }}
                  </h4>
                  <template slot="inputs">
                    <h4>
                      {{ additionally.price.text }}
                    </h4>
                  </template>
                  <md-button
                    slot="footer"
                    class="md-simple md-success md-lg"
                    @click="$emit('openAdditionally', additionally)"
                  >
                    Подробнее
                  </md-button>
                </login-card>
              </div>
              <!---->
            </div>
          </div>
          
          <!--
          <div
            class="md-layout course-mg-bottom">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="info-title">Генри camp 2022 «Лето современного ребенка»</h4>
            <h4 class="info-title green-text">(для детей от 7 до 13 лет)</h4>
            <hr />
            <div class="md-layout">
              <div class="md-layout-item text-align-left md-small-size-100 md-size-65">
                <span class="info-title info-text">Продолжительность: </span> <span class="info-text">3 смены по 10 дней</span>
              </div>
              <div class="md-layout-item text-align-left md-small-size-100 md-size-35">
                <span class="info-title info-text">Стоимость: </span> <span class="info-text">15000 руб.</span>
              </div>
              <div class="md-layout-item text-align-left md-small-size-100 md-size-65">
                <span class="info-title info-text">Время: </span> <span class="info-text">Пн-Пт 10:00-14:00</span>
              </div>
              <div class="md-layout-item text-align-left md-small-size-100 md-size-35">
                <span class="info-title info-text">Группа: </span> <span class="info-text">до 6 человек</span>
              </div>
            </div>
            <hr />
            <p class="course-description"><span class="info-title green-text">Описание:  </span>Генри camp 2022 - это летний городской лагерь неполного дня, программу которого мы постарались сделать максимально насыщенной и запоминающейся для каждого ребенка! Помимо классических летних активностей, ребята прокачают свои навыки в области робототехники, схемотехники и пайки. Попробуют себя в роли повара на мастер-классе в пиццерии ДоДо. Окунутся в историю России на тематических экскурсиях современного парка "Россия-Моя история". Посетят Ростовский зоопарк – один из крупнейших в России и Европе. Поборются в командных VR-играх на арене виртуальной реальности.  Ну и какое же лето без солнца, бассейна и горок? Именно поэтому уже 2-й год подряд мы с ребятами выбираем отдых в аквапарке H2О. Будет круто, ярко и весело. Кстати, с ребятами будут работать педагоги, с солидным опытом работы в таких Всероссийских лагерях, как Артек и Радуга. Ждём вас! </p>
            
          </div>
          
          <div id="camp" class="md-layout-item md-size-50 md-small-size-100 course-parent">
            <md-card>
              <carousel
                :per-page="1"
                loop
                :speed="700"
                :autoplay-timeout="1500"
                :mouse-drag="true"
                navigationEnabled
                navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
                navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
              >
                <slide
                  class="parent-cover"
                >
                  <img :src="require('../assets/img/12.png')" alt="camp" class="cover" />
                </slide>
              </carousel>
            </md-card>
            <br />
            <br />
          </div>
          <div class="course-button">
            <div class="md-layout">
              <div class="md-layout-item">
                <SVButton @clicked="openLink" text="Программа и график смен" color="green"></SVButton>
              </div>
              <div class="md-layout-item">
                <SVButton @clicked="$root.$emit('subscribeOpen', {message:{text: 'Записаться на Генри camp 2022'}})" text="Записаться в Генри camp 2022" color="green"></SVButton>
              </div>
            </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import { SVButton } from "@/components";
export default {
  components: {
    LoginCard,
    SVButton
  },
  props: ['courses', 'laptop'],
  methods: {
    openLink(){
      window.open('https://drive.google.com/file/d/1YXgoMKK2Ek3Kdf1adPVotA8qdwqCBUTA/view?usp=sharing', '_blank')
    },
    courseLeft(index, num) {
      if (index % 2 == 0 && window.innerWidth > 1000) {
        if (num == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (num == 1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
}
</script>
<style scoped>
.course-parent{
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
.course-button{
	flex: 1 1 auto;
}
.s-text{
  color: darkgrey;
  font-weight: 400;
}
.green-text{
  color: #42b983;
}
.text-align-left {
  text-align: left;
}
.info-text {
  font-size: 0.84em;
}
.price-title {
  color: #fafafa;
  font-weight: 500;
}
.course-m-bottom {
  margin-top: 14vh;
}
.m-left {
  margin-left: 5% !important;
}
.login-cart-center {
  margin: 100px auto;
}
.course-description {
  font-size: 0.9em;
  text-align: left !important;
}
.parent-cover {
  max-height: 30%;
}
.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.course-mg-bottom {
  margin-top: 10vh;
}
.additionally-description {
  text-indent: 30px;
  font-weight: 400;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.green-text {
  color: #4caf50 !important;
}
</style>