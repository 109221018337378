<template>
  <div class="main main-raised">
    <div class="section text-center" id="instagram">
      <div class="container">
        <h2 class="title">Давайте дружить в инстаграм!</h2>
        <a href="https://www.instagram.com/club_genry/" target="blank" class="link-click" rel="nofollow">@club_genry</a>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <iframe
              src="https://cdn.lightwidget.com/widgets/da8126dcd92351a2b327aff4990b4747.html"
              title="Instagram Club Genry"
              scrolling="no"
              allowtransparency="true"
              class="lightwidget-widget"
              style="width:100%;border:0;overflow:hidden;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
.link-click {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 1.3em;
  margin-bottom: 30px;
}
</style>