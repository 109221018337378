<template>
  <div class="main main-raised">
    <div class="section text-center" id="team">
      <div class="container">
        <h2 class="title">Наша Команда</h2>
        <div class="md-layout">
          <div
            v-for="teacher in teachers"
            :key="teacher.name"
            class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
          >
            <div class="team-player">
              <md-card class="md-card-plain">
                <div class="md-layout-item md-size-50 mx-auto">
                  <img
                    :src="teacher.image"
                    :alt="teacher.name"
                    class="img-raised rounded-circle img-fluid"
                  />
                </div>
                <h4 class="card-title">
                  {{ teacher.name }}
                  <br />
                  <h4 class="card-description text-muted">
                    {{ teacher.type }}
                  </h4>
                </h4>
                <md-card-content>
                  <p class="card-description">
                    {{ teacher.description }}
                  </p>
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['teachers']
}
</script>
<style scoped>
</style>
