var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section section-contacts",attrs:{"id":"contacts"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-xsmall-size-100 mx-auto"},[_c('h2',{staticClass:"text-center title"},[_vm._v(_vm._s(_vm.map.title))]),_c('h4',{staticClass:"text-center description"},_vm._l((_vm.map.descriptions),function(description){return _c('h3',{key:description.text,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('mapsClick', [
                {
                  element: description,
                  parent:  _vm.map.descriptions,
                  path: `/body/maps/descriptions`
                }
              ])}}},[_vm._v(" "+_vm._s(description.text)+" ")])}),0),_c('br'),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('yandex-map',{staticClass:"map-footer",staticStyle:{"width":"100%","height":"80vh"},attrs:{"settings":_vm.settings,"coords":_vm.map.yandexMap.coords,"zoom":"16"}},[_c('ymap-marker',{attrs:{"markerId":1,"coords":_vm.map.yandexMap.coords,"icon":{ color: 'red' },"balloon":{
                    header: _vm.map.yandexMap.headerMarker,
                    body: _vm.map.yandexMap.bodyMarker
                  }}})],1)],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }