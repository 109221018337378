<template>
  <div class="main main-raised">
    <div class="section text-center" id="team">
      <div class="container">
        <h2 class="title">Наши друзья и партнеры </h2>
        <div class="md-layout">
          <div
            v-for="friend in friends.data"
            :key="friend.text.name"
            class="image md-layout-item md-size-25 md-medium-size-50 md-small-size-100"
          >
            <a class="team-player" :href="friend.text.link" target="_blank">
              <div class="md-card-plain">
                <div class="md-layout-item md-size-100 mx-auto">
                  <img
                    :src="friend.image.headimage.link"
                    :alt="friend.text.name"
                    class="img-fluid"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['friends']
}
</script>
<style scoped>
.image {
  margin: 30px auto;
}
</style>
