import { render, staticRenderFns } from "./Сertificates.vue?vue&type=template&id=96a27598&scoped=true"
import script from "./Сertificates.vue?vue&type=script&lang=js"
export * from "./Сertificates.vue?vue&type=script&lang=js"
import style0 from "./Сertificates.vue?vue&type=style&index=0&id=96a27598&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a27598",
  null
  
)

export default component.exports