<template>
  <div class="main main-raised">
    <div class="section text-center" id="price">
      <div class="container">
        <h2 class="title">{{ price.title.title.text }}</h2>
        <div class="team">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-50 md-small-size-80 course-m-bottom login-cart-center"
            >
              <login-card>
                <template slot="inputs">
                  <h3 
                    class="green-color"
                    @click.stop="$emit('priceClick', [
                      {
                        element: price.leftColumn.title.title,
                        parent:  price.leftColumn.title,
                        path: `/body/price/leftColumn/title`
                      }
                    ])"
                  >
                    {{ price.leftColumn.title.title.text }}
                  </h3>
                  <h4 
                    v-for="(description, index) in price.leftColumn.descriptions"
                    :key="description.title.text"
                    @click.stop="$emit('priceClick', [
                      {
                        element: description.title,
                        parent:  price.leftColumn.descriptions[index],
                        path: `/body/price/leftColumn/descriptions[${index}]`
                      }
                    ])"
                  >
                    <h3 class="title">{{ description.title.text }}</h3>
                    <span v-html="description.description.text"></span>
                  </h4>
                </template>
              </login-card>
            </div>
            <div
              class="md-layout-item md-size-50 md-small-size-80 course-m-bottom login-cart-center"
            >
              <login-card>
                <template slot="inputs">
                  <h3 
                    class="green-color"
                    @click.stop="$emit('priceClick', [
                      {
                        element: price.rightColumn.title.title,
                        parent:  price.rightColumn.title,
                        path: `/body/price/rightColumn/title`
                      }
                    ])"
                  >
                    {{ price.rightColumn.title.title.text }}
                  </h3>
                  <h3 
                    class="upper-text-size"
                    @click.stop="$emit('priceClick', [
                      {
                        element: price.rightColumn.description.description,
                        parent:  price.rightColumn.description,
                        path: `/body/price/rightColumn/description`
                      }
                    ])"
                  >
                    {{ price.rightColumn.description.description.text }}
                  </h3>
                </template>
                <md-button
                  slot="footer"
                  class="md-success md-lg"
                  @click.stop="$emit('priceClick', [
                    {
                      element: price.rightColumn.link.link,
                      parent:  price.rightColumn.link,
                      path: `/body/price/rightColumn/link`
                    }
                  ])"
                >
                  Открыть прайс-лист
                </md-button>
              </login-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
export default {
  components: {
    LoginCard
  },
  props: ['price']
}
</script>
<style scoped>
.upper-text-size {
  font-size: 2.0rem;
  padding-top: 12px;
}
.login-cart-center {
  margin: 100px auto;
}
.green-color {
  color: #4caf50;
  font-weight: 600;
  margin-bottom: 30px;
}
.course-m-bottom {
  margin-top: 14vh;
}
</style>