var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section text-center",attrs:{"id":"feedback"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v("Отзывы")]),_c('div',{staticClass:"md-layout"},_vm._l((_vm.feedbacks),function(feedback,index){return _c('login-card',{key:feedback.text.text.name,staticClass:"md-layout-item md-size-40 md-small-size-80 md-elevation-6 ma-20"},[_c('template',{slot:"inputs"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('feedbackClick', [
                  {
                    element: feedback.text.text,
                    parent:  feedback.text,
                    path: `/body/feedbacks[${index}]/text`
                  },
                  {
                    element: feedback.image.headimage,
                    parent:  feedback.image,
                    path: `/body/feedbacks[${index}]/image/headimage/link`
                  }
                ])}}},[_c('img',{staticClass:"img-raised rounded-circle img-fluid img-fix-size",attrs:{"src":feedback.image.headimage.link,"alt":feedback.text.text.name}}),_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(feedback.text.text.name)+" ")]),_c('p',{staticClass:"card-description"},[_vm._v(" "+_vm._s(feedback.text.text.description)+" ")])])])])],2)}),1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }