<template>
  <div class="main main-raised">
    <div class="section text-center" id="feedback">
      <div class="container">
        <h2 class="title">Отзывы</h2>
        <div class="md-layout">
          <login-card
            v-for="(feedback, index) in feedbacks"
            :key="feedback.text.text.name"
            class="md-layout-item md-size-40 md-small-size-80 md-elevation-6 ma-20">
            <template slot="inputs">
              <div 
                class="md-layout"
              >
                <div class="md-layout-item md-size-100"
                  @click.stop="$emit('feedbackClick', [
                    {
                      element: feedback.text.text,
                      parent:  feedback.text,
                      path: `/body/feedbacks[${index}]/text`
                    },
                    {
                      element: feedback.image.headimage,
                      parent:  feedback.image,
                      path: `/body/feedbacks[${index}]/image/headimage/link`
                    }
                  ])"
                  >
                  <img
                    class="img-raised rounded-circle img-fluid img-fix-size"
                    :src="feedback.image.headimage.link"
                    :alt="feedback.text.text.name"
                  />
                  <h4 class="card-title">
                    {{ feedback.text.text.name }}
                  </h4>
                  <p class="card-description">
                    {{ feedback.text.text.description }}
                  </p>
                </div>
              </div>
            </template>
          </login-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
export default {
  props: ['feedbacks'],
  components: {
    LoginCard
  }
}
</script>
<style scoped>
.img-fluid{
  margin-top: 20px;
}
.ma-20 {
  margin-top: 5%;
}
.md-elevation-6 {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12) !important;
}
.img-fix-size {
  width: 30%;
}
</style>
