<template>
  <div class="main main-raised">
    <div class="section section-contacts" id="contacts">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-xsmall-size-100 mx-auto">
            <h2 class="text-center title">{{ map.title }}</h2>
            <h4 class="text-center description">
              <h3
                v-for="(description) in map.descriptions"
                :key="description.text"
                @click.stop="$emit('mapsClick', [
                  {
                    element: description,
                    parent:  map.descriptions,
                    path: `/body/maps/descriptions`
                  }
                ])"
              >
                {{ description.text }}
              </h3>
            </h4>
            <br />
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <yandex-map
                  :settings="settings"
                  :coords="map.yandexMap.coords"
                  class="map-footer"
                  style="width: 100%; height: 80vh;"
                  zoom="16"
                >
                  <ymap-marker
                    :markerId="1"
                    :coords="map.yandexMap.coords"
                    :icon="{ color: 'red' }"
                    :balloon="{
                      header: map.yandexMap.headerMarker,
                      body: map.yandexMap.bodyMarker
                    }"
                  ></ymap-marker>
                </yandex-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
  props: ['map'],
  components: {
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      settings: {
        apiKey: this.map.yandexMap.apiKey,
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
        SemiSite: "none"
      }
    }
  }
}
</script>
<style scoped>
</style>