<template>
  <div class="main main-raised">
    <div class="section section-contacts" id="contacts">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-xsmall-size-100 mx-auto">
            <h2 class="text-center title">Наши контакты</h2>
            <h4 class="text-center description md-layout md-gutter md-alignment-center">
              <div class="md-layout-item md-size-50">
                <h3 class="title">
                  Адрес (Сельмаш):
                </h3>
                <h4
                  v-for="(description) in map.descriptionsOne"
                  :key="description.text"
                >
                  {{ description.text }}
                </h4>
                <h4 class="title">
                  Запись / консультация
                  <a href="https://api.whatsapp.com/send?phone=79185580172"
                     target="_blank" rel="external">
                    <span class="color-green">WhatsApp: 8-918-558-01-72</span></a>
                </h4>
              </div>
              <div class="md-layout-item md-size-50">
                <h3 class="title">
                  Адрес (Западный):
                </h3>
                <h4
                  v-for="(description) in map.descriptionsTwo"
                  :key="description.text"
                >
                  {{ description.text }}
                </h4>
                <h4 class="title">
                  Запись / консультация
                  <a href="https://api.whatsapp.com/send?phone=79882558905"
                     target="_blank" rel="external">
                    <span class="color-green">WhatsApp: 8-988-255-89-05</span></a>
                </h4>
              </div>
            </h4>
            <br />
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <yandex-map
                  :settings="settings"
                  :coords="map.yandexMap.coords"
                  class="map-footer"
                  style="width: 100%; height: 80vh;"
                  zoom="13"
                >
                  <ymap-marker
                    v-for="(marker, index) in map.yandexMap.markers"
                    :markerId="index"
                    :key="index"
                    :coords="marker.coords"
                    :icon="{ color: 'red' }"
                    :balloon="{
                      header: marker.headerMarker,
                      body: marker.bodyMarker
                    }"
                  ></ymap-marker>
                </yandex-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
  props: ['map'],
  components: {
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      settings: {
        apiKey: this.map.yandexMap.apiKey,
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
        SemiSite: "none"
      }
    }
  }
}
</script>
<style scoped>
.color-green{
  color: #4CAF50;
}
</style>