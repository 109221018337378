<template>
  <div class="main main-raised">
    <div class="section text-center" id="team">
      <div class="container">
        <h2 class="title">Наши достижения</h2>
        <div class="md-layout">
          <div
            v-for="(certificate) in certificates"
            :key="certificate.text.name"
            class="image md-layout-item md-small-size-100"
            :class="{
              'md-size-33': certificate.text.text.big == 'true',
              'md-size-25': certificate.text.text.big != 'true'
              }"
          >
            <div class="md-card-plain">
              <div 
                class="md-layout-item md-size-100 mx-auto"
              >
                <img
                  :src="certificate.image.headimage.link"
                  :alt="certificate.text.text.name"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['certificates']
}
</script>
<style scoped>
.image {
  margin: 30px auto;
}
</style>
