import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    access: null,
    apiData: null,
    loading: true,
    preLoading: false
  },
  mutations: {
    accessUpdate (state, payload) {
      state.access = payload
    },
    dataLoaded (state, apiData) {
      state.apiData = apiData
      //console.log(apiData)
    },
    loaded (state) {
      state.loading = false
    },
    preLoaded(state) {
      state.preLoading = true
    }
  }
})
export default store
