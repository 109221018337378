<template>
  <div class="main main-raised z-index-up">
    <div class="section" id="life">
      <div class="container text-center">
        <h2 class="title">Генри life</h2>
        <!--<h5 class="description">(О нас, время чтения - 2 мин):</h5>-->
        <div class="md-layout">
          <div class="md-layout-item md-size-100 text-center m-top">
            <carousel-3d>
              <slide v-for="(slide, i) in collage" :index="i" :key="i">
                <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                  <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.src">
                </template>
              </slide>
            </carousel-3d>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  props: ['collage'],
  components: {
    Carousel3d,
    Slide
  }
}
</script>
<style>
.VideoBg video {
  object-fit: cover;
}
.m-top {
  margin-top: 50px;
}
.section > .container > .title {
  margin-bottom: 0 !important;
}
.text-bold {
  font-weight: 700;
}
.padding-off {
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0px !important;
}
.z-index-up {
  z-index: 100 !important;
}
.mt-150 {
  margin-top: 30px;
}
.max-size {
  width: 100%;
}
.text-formating {
  text-align: left;
}
.text-formating > h4 {
  font-size: 1.0rem;
  text-indent: 40px;
}
</style>