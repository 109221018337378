var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section text-center",attrs:{"id":"team"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v("Сертификаты")]),_c('div',{staticClass:"md-layout"},_vm._l((_vm.certificates),function(certificate,index){return _c('div',{key:certificate.text.name,staticClass:"image md-layout-item md-small-size-100",class:{
            'md-size-33': certificate.text.text.big == 'true',
            'md-size-25': certificate.text.text.big != 'true'
            }},[_c('div',{staticClass:"md-card-plain"},[_c('div',{staticClass:"md-layout-item md-size-100 mx-auto",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('certsfClick', [
                {
                  element: certificate.text.text,
                  parent:  certificate.text,
                  path: `/body/certificates[${index}]/text`
                },
                {
                  element: certificate.image.headimage,
                  parent: certificate.image,
                  path: `/body/certificates[${index}]/image/headimage/link`
                }
              ])}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":certificate.image.headimage.link,"alt":certificate.text.text.name}})])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }