<template>
  <div class="wrapper" @wheel="wheel" id="heading" v-if="$store.state.apiData != null">
    <template>
      <vue-topprogress ref="topProgress"></vue-topprogress>
    </template>
    <video-bg 
      :sources="[$store.state.apiData.header.headingVideo.videos.link]" 
      :img="$store.state.apiData.header.headingImage.headimage.link"
    >
      <parallax  class="section page-header header-filter">
        <div 
          class="container"
          @click="$emit('navBodyClick', [
            {
              element: $store.state.apiData.header.headingImage.headimage,
              parent: $store.state.apiData.header.headingImage,
              path: '/header/headingImage/headimage/link'
            },
            {
              element: $store.state.apiData.header.headingVideo.videos,
              parent: $store.state.apiData.header.headingVideo,
              path: '/header/headingVideo/videos/link'
            }
          ])"
        >
          <div class="md-layout">
            <div
              :class="{'mt-100': laptop}"
              class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100"
            >
              <h2 class="title">
                <span
                  @click.stop="$emit('navBodyClick', [{
                    element: $store.state.apiData.header.clubPresentation.elementTitle.title,
                    parent: $store.state.apiData.header.clubPresentation.elementTitle,
                    path: '/header/clubPresentation/elementTitle'
                  }])"
                >
                  {{ $store.state.apiData.header.clubPresentation.elementTitle.title.text }}
                </span>
                <div 
                  class="mini-title"
                  @click.stop="$emit('navBodyClick', [{
                    element: $store.state.apiData.header.clubPresentation.elementDescription.description,
                    parent: $store.state.apiData.header.clubPresentation.elementDescription,
                    path: '/header/clubPresentation/elementDescription'
                  }])"
                >
                  {{ $store.state.apiData.header.clubPresentation.elementDescription.description.text }}
                </div>
              </h2>
              <br v-if="laptop">
              <h4>
                <h3
                  v-for="clubskill in $store.state.apiData.header.clubPresentation.clubskills"
                  :key="clubskill.text"
                  @click.stop="$emit('navBodyClick', [{
                    element: clubskill,
                    parent: $store.state.apiData.header.clubPresentation.clubskills,
                    path: '/header/clubPresentation/clubskills'
                  }])"
                  class="clubskills"
                  href="#"
                  v-show="clubskill.helpers.show"
                >
                  <i class="fas fa-check"></i> {{ clubskill.text }}
                </h3>
              </h4>
            </div>
            <div
              v-if="laptop" class="header-filter md-layout-item md-small-size-80 md-size-40 center-header"
              @click.stop="$emit('navBodyClick', [{
                element: $store.state.apiData.header.clubForm.titles[0],
                parent: $store.state.apiData.header.clubForm.titles,
                path: '/header/clubForm/titles'
              }])">
              <div>
                <h3 class="header-h3">
                  <div 
                    v-for="(title, index) in $store.state.apiData.header.clubForm.titles"
                    :key="index"
                    v-show="title.helpers.show"
                  >
                    {{ title.text }}
                  </div>
                </h3>
              </div>
              <login-card v-if="laptop" class="login-card">
                <template slot="inputs">
                  <div class="md-layout">
                    <div class="md-center md-layout-item md-size-100">
                      <h5 class="h3-fixed"
                        v-for="(title, index) in $store.state.apiData.header.clubForm.panelDescription"
                        :key="index"
                        @click.stop="$emit('navBodyClick', [{
                          element: title,
                          parent: $store.state.apiData.header.clubForm.panelDescription,
                          path: '/header/clubForm/panelDescription'
                        }])"
                      >
                        {{ title.text }}
                      </h5>
                    </div>
                    <div class="md-layout-item md-size-80 mx-auto text-center">
                      <md-field>
                        <label>Администратор свяжется с Вами</label>
                        <md-input
                          v-model="subscribe.phone"
                          placeholder="8(928)123-45-67"
                          @focus="checkPhone"
                          @input="nextPhoneCheck"
                          type="text"
                          maxlength="15"
                          minlength="15"
                        ></md-input>
                      </md-field>
                      <span v-if="isPhoneInvalid" class="md-helper-text red">Введен некорректный номер!</span>
                    </div>
                    <div class="md-layout-item md-size-100 mx-auto text-center">
                      <md-button
                        class="md-success modal-button md-dense md-mini"
                        @click="sendSaleMail"
                        :disabled="sendButton">
                        Записаться
                      </md-button>
                    </div>
                  </div>
                </template>
              </login-card>
            </div>
          </div>
        </div>
      </parallax>
    </video-bg>

    <!-- О Нас -->
    <choose-us
      v-if="$store.state.preLoading"
      @chooseUsClick="$emit('navBodyClick', $event)"
      :sectionData="$store.state.apiData.body.chooseUs"
      class="section-custom"/>
    
    <!-- О нас :collage="$store.state.apiData.collage"-->
    <about-us
      v-if="$store.state.preLoading"
      @aboutUsClick="$emit('navBodyClick', $event)"
      :sectionData="$store.state.apiData.body.aboutUs"
      class="section-custom"/>
    
    <!-- Курсы -->
    <courses 
      v-if="$store.state.preLoading"
      @coursesClick="$emit('navBodyClick', $event)"
      :courses="$store.state.apiData.body.courses"
      :laptop="laptop"
      v-on:subscribeOpen="subscribeOpen"
      v-on:openAdditionally="openAdditionally"
      class="section-custom"/>
    
    <!-- Отызвы -->
    <feedback
      v-if="$store.state.preLoading"
      @feedbackClick="$emit('navBodyClick', $event)"
      :feedbacks="$store.state.apiData.body.feedbacks"
      class="section-custom"/>
    
    <!-- Сертификаты -->
    <certsf
      v-if="$store.state.preLoading"
      @certsfClick="$emit('navBodyClick', $event)"
      :certificates="$store.state.apiData.body.certificates"
      class="section-custom"/>

    <!-- Цены -->
    <price
      v-if="$store.state.preLoading"
      @priceClick="$emit('navBodyClick', $event)"
      :price="$store.state.apiData.body.price"
      class="section-custom"/>

    <!-- Команда 
    <teachers 
      :teachers="$store.state.apiData.teachers"
      class="section-custom"/>-->

    <!-- Друзья -->
    <friends 
      v-if="$store.state.preLoading"
      @friendsClick="$emit('navBodyClick', $event)"
      :friends="$store.state.apiData.body.friends"
      class="section-custom"/>

    <!-- insta -->
    <instagram
      v-if="$store.state.preLoading"
      class="section-custom"/>
      
    <!-- life
    <life
      :collage="$store.state.apiData.collage"
      class="section-custom"/> -->

    <!-- Карта -->
    <maps
      v-if="$store.state.preLoading"
      @mapsClick="$emit('navBodyClick', $event)"
      :map="$store.state.apiData.body.maps"
      class="section-custom"/>

    <modal v-if="okModal" @close="okModalHide">
      <template slot="header">
        <h3 class="modal-title text-center">Запись успешно сформирована!</h3>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="okModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h4>Администратор свяжется с Вами в ближайшее время!</h4>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="noModal" @close="noModalHide">
      <template slot="header">
        <h3 class="modal-title text-center">Запись не удалась!</h3>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="noModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h4>
              Повторите пожалуйста еще раз, либо позвоните нам по номеру
              {{ $store.state.apiData.phone }}
            </h4>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="classicModal" @close="classicModalHide">
      <template slot="header">
        <h4 class="modal-title text-center">{{ subscribe.courseTitle }}</h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="classicModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <md-field>
              <label>Введите Ваш номер телефона</label>
              <md-input
                v-model="subscribe.phone"
                placeholder="8(928)123-45-67"
                @focus="checkPhone"
                @input="nextPhoneCheck"
                type="text"
                maxlength="15"
                minlength="15"
              ></md-input>
            </md-field>
            <span v-if="isPhoneInvalid" class="md-helper-text red"
              >Введен некорректный номер!</span
            >
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="mt">
          <md-button
            class="md-success modal-button"
            @click="sendMail(subscribe)"
            v-bind:disabled="sendButton"
            >Записаться</md-button
          >
        </div>
      </template>
    </modal>

    <modal v-if="saleModal" @close="saleModal = false">
      <template slot="header">
        <h3 class="modal-title text-center">
          Закажите обратный звонок, или позвоните нам:
          <br>
          8(918)558-01-72
        </h3>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="saleModal = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <md-field>
              <label>Введите Ваш номер телефона</label>
              <md-input
                v-model="subscribe.phone"
                placeholder="8(928)123-45-67"
                @focus="checkPhone"
                @input="nextPhoneCheck"
                type="text"
                maxlength="15"
                minlength="15"
              ></md-input>
            </md-field>
            <span v-if="isPhoneInvalid" class="md-helper-text red"
              >Введен некорректный номер!</span
            >
          </div>
        </div>
      </template>


      <template slot="footer">
        <md-button
          class="md-success modal-button"
          @click="sendSaleMail"
          v-bind:disabled="sendButton">
          Заказать!</md-button>
      </template>
    </modal>

    <modal v-if="additionallyModal" @close="additionallyModal = false">
      <template slot="header">
        <h4 class="modal-title text-center">
          {{ additionallyData.fullname.text }}
        </h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="additionallyModal = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div class="md-layout md-fixed-header">
          <md-tabs class="md-primary fix-tab-width">
            <md-tab md-label="Проблемы">
              <div class="md-layout-item md-size-100 text-left">
                <div v-for="pros in additionallyData.pros" :key="pros.text">
                  <p>
                    {{ pros.text }}
                  </p>
                </div>
              </div>
            </md-tab>
            <md-tab md-label="Описание">
              <div class="md-layout-item md-size-100 text-left">
                <p>
                  {{ additionallyData.description.text }}
                </p>
              </div>
            </md-tab>

            <md-tab md-label="Результаты диагностики">
              <div class="md-layout-item md-size-100 text-left">
                <div v-for="result in additionallyData.results" :key="result.text">
                  <p>
                    {{ result.text }}
                  </p>
                </div>
              </div>
            </md-tab>
          </md-tabs>
          <div
            class="md-layout-item md-size-70 md-small-size-100 mx-auto text-center"
          >
            <md-field>
              <label>Введите Ваш номер телефона</label>
              <md-input
                v-model="subscribe.phone"
                placeholder="8(928)123-45-67"
                @focus="checkPhone"
                @input="nextPhoneCheck"
                type="text"
                maxlength="15"
                minlength="15"
              ></md-input>
            </md-field>
            <span v-if="isPhoneInvalid" class="md-helper-text red"
              >Введен некорректный номер!</span
            >
            <div class="mt">
              <md-button
                class="md-success modal-button"
                @click="
                  sendMail({
                    phone: subscribe.phone,
                    message: additionallyData.fullname.text
                  })
                "
                v-bind:disabled="sendButton"
                >Записаться!</md-button
              >
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import VideoBg from 'vue-videobg'
import Film from "./Film.vue";
import Life from "./Life.vue";
import Friends from "./Friends.vue";
import Feedback from "./Feedback.vue";
import AboutUs from "./AboutUs.vue";
import CallWidget from "./CallWidget.vue";
import Maps from "./Maps.vue";
import Price from "./Price.vue";
import Instagram from "./Instagram.vue";
import Teachers from "./Teachers.vue";
import ChooseUs from "./ChooseUs.vue";
import Courses from "./Courses.vue";
import Certsf from "./Сertificates.vue"
import {API} from "../../api"
import { LoginCard } from "@/components";
import { Modal } from "@/components";

export default {
  components: {
    Certsf,
    VideoBg,
    Film,
    Life,
    Friends,
    Feedback,
    AboutUs,
    CallWidget,
    Maps,
    Price,
    Instagram,
    Teachers,
    Courses,
    ChooseUs,
    LoginCard,
    Modal
  },
  name: "Landing",
  bodyClass: "landing-page",
  props: {
    clubLogo: {
      type: String,
      default: require("@/assets/img/club/Robot1.png")
    }
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          question: 'Какой возраст у Вашего ребенка?',
          selectedText: null,
          selectedId: null,
          selecting: [
            {
              id: 1,
              text: 'От 2 до 4 лет',
              if: []
            },
            {
              id: 2,
              text: 'От 4 до 6 лет',
              if: []
            },
            {
              id: 3,
              text: 'От 7 до 10 лет',
              if: []
            },
            {
              id: 4,
              text: 'От 11 до 16 лет',
              if: []
            }
          ]
        },
        {
          id: 2,
          question: 'Какие интересы у Вашего ребенка',
          selectedText: null,
          selectedId: null,
          selecting: [
            {
              id: 1,
              text: 'От 2 до 4 лет',
              if: []
            },
            {
              id: 2,
              text: 'От 4 до 6 лет',
            },
            {
              id: 3,
              text: 'От 7 до 10 лет',
            },
            {
              id: 4,
              text: 'От 11 до 16 лет',
            }
          ]
        }
      ],
      laptop: false,
      showCall: false,
      additionallyModal: false,
      additionallyData: null,
      isPhoneInvalid: false,
      sendButton: false,
      name: null,
      email: null,
      message: null,
      classicModal: false,
      okModal: false,
      noModal: false,
      saleModal: false,
      subscribe: {
        courseTitle: "",
        phone: ""
      }
    };
  },
  mounted() {
    document.onreadystatechange = () => { 
      if (document.readyState == "complete") { 
        this.$store.commit('loaded')
        this.$store.commit('preLoaded')
      } 
    }
    this.laptop = window.innerWidth > 1000
    if(window.innerWidth < 1000) this.showCall = true
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.$store.state.apiData.headimage})`
      };
    }
  },
  methods: {
    scrollToElement(element) {
      let element_id = document.getElementById(element);
      if (element_id) {
        element_id.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    },
    wheel(ev){
      this.laptop = window.innerWidth > 1000
      this.showCall = ev.pageY > 400 && window.innerWidth > 1000
      if(window.innerWidth < 1000) this.showCall = true
    },
    openAdditionally(additionally) {
      this.additionallyModal = true;
      this.additionallyData = additionally;
      this.subscribe.courseTitle = this.additionallyData.fullname;
    },
    nextPhoneCheck() {
      this.isPhoneInvalid = false;
      if (this.subscribe.phone != null) {
        if (this.subscribe.phone.length == 1) {
          this.subscribe.phone = "8(" + this.subscribe.phone[0];
        } else if (this.subscribe.phone.length == 5) {
          this.subscribe.phone += ")";
        } else if (this.subscribe.phone.length == 6) {
          if(this.subscribe.phone[5] !=')') {
            let b = this.subscribe.phone[5]
            this.subscribe.phone = this.subscribe.phone.substring(0,  this.subscribe.phone.length - 1) + ')' + b
          }
        } else if (this.subscribe.phone.length == 9) {
          this.subscribe.phone += "-";
        } else if (this.subscribe.phone.length == 12) {
          this.subscribe.phone += "-";
        }
      }
    },
    checkPhone() {
      if (this.subscribe.phone == null || this.subscribe.phone.length == 0) {
        this.subscribe.phone = "8(";
      }
    },
    async sendSaleMail() {
      this.subscribe.courseTitle = "Заказывают обратный звонок:";
      await this.sendMail(this.subscribe);
    },
    noModalHide() {
      this.noModal = false;
    },
    async sendMail(info) {
      this.sendButton = true;
      this.$refs.topProgress.start();
      let args = {
        data: {
          phone: info.phone,
          message: info.courseTitle
        }
      };
      const error = !(/^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/.test(args.data.phone))
      if (!error) {
        this.isPhoneInvalid = false;
        await API.post("mail", args.data)
          .then(() => {
            this.$refs.topProgress.done();
            this.additionallyModal = false;
            this.saleModal = false;
            this.classicModal = false;
            this.okModal = true;
            this.sendButton = false;
          })
          .catch(() => {
            this.$refs.topProgress.fail();
            this.additionallyModal = false;
            this.saleModal = false;
            this.classicModal = false;
            this.noModal = true;
            this.sendButton = false;
          });
      } else {
        this.isPhoneInvalid = true;
        this.$refs.topProgress.fail();
        this.$refs.topProgress.done();
        this.sendButton = false;
      }
    },
    subscribeOpen(course) {
      debugger
      this.subscribe.courseTitle = course.message;
      this.classicModal = true;
    },
    classicModalHide() {
      this.subscribe.courseTitle = "";
      this.classicModal = false;
    },
    okModalHide() {
      this.okModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.mini-title {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2em;
}
.green-text {
  color: #4caf50;
}
.clubskills {
  cursor: pointer;
  line-height: 0.2em;
  font-weight: 600;
  font-size: 1.4em !important;
}
.mt {
  margin-top: 30px;
}
.header-h3 {
  text-align: center;
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 50px;
  font-size: 1.3em;
}
.red {
  color: red;
  font-size: 0.8em;
  float: left;
}
.md-card {
  position: unset !important;
}
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}
.md-has-textarea + .md-layout {
  margin-top: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-100 {
  margin-top: 200px;
}
.light {
  font-weight: 100;
}
.img-club-logo {
  margin-left: 15%;
  max-width: 40vh;
  margin-top: 100px;
}
.modal-title {
  width: 70%;
  font-weight: 400;
}
.modal-input {
  display: flex;
  justify-content: center !important;
}
.modal-button {
  text-align: center;
}
.login-cart-center {
  margin: 100px auto;
}
.bold {
  font-weight: 700;
}
.cont {
  height: 405px;
}
p {
  margin-bottom: 0 !important;
}
.section-custom {
  margin-bottom: 130px;
}
.center-header {
  margin: 0 auto;
  margin-top: 19.25%;
  margin-bottom: 15%;
  text-align: center;
}
.addres {
  margin-top: -10px;
  margin-bottom: 30px;
}
.mini-icon {
  width: 5%;
  display: inline-block;
  margin: 0 auto;
  height: 100%;
  text-align: center;
}
.h3-fixed {
  color: #42b983;
  font-size: 1.35em;
  font-weight: 400;
}
.md-center {
  text-align: center;
}
.login-card {
  width: 85%;
  background-color: rgba(255, 255, 255, 0.85) !important;
}
.login-card:hover {
  background-color: rgba(255, 255, 255, 1.00) !important;
}
.fix-tab-width{
  width: 100% !important;
}
@media (max-width: 960px) {
.clubskills {
  font-size: 1.5em !important;
}
.header-h3 {
  margin-top: -50px;
  margin-bottom: 0px;
}
  .h3-fixed {
    margin-top: 0;
  }
  .img-club-logo {
    display: none !important;
  }
  .mini-icon {
    height: 100%;
    display: block;
    text-align: center;
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-right: 50px;
  }
}
</style>
