// =========================================================
// * Vue Material Kit - v1.2.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-kit/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import 'vue-lazy-youtube-video/dist/style.css';
import MaterialKit from "./plugins/material-kit";
import vueTopprogress from "vue-top-progress";
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import store from './store.js'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
Vue.$cookies.config('7d')
//import VideoBg from 'vue-videobg';

import VuePictureSwipe from 'vue-picture-swipe';
Vue.component('vue-picture-swipe', VuePictureSwipe);

Vue.config.productionTip = true;

Vue.use(MaterialKit);
Vue.component('LazyYoutubeVideo', LazyYoutubeVideo);
//Vue.component('video-bg', VideoBg);
Vue.use(vueTopprogress);
const NavbarStore = {
  showNavbar: false
};

Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
