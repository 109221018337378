var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section",attrs:{"id":"courses"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"},_vm._l((_vm.courses.titles),function(title,index){return _c('div',{key:index},[_c('h2',{staticClass:"title text-center",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: title,
                parent: _vm.courses.titles,
                path: '/body/courses/titles'
              }])}}},[_vm._v(" "+_vm._s(title.text)+" ")])])}),0)]),_c('div',{staticClass:"features text-center"},_vm._l((_vm.courses.data),function(course,index1){return _c('div',{key:index1,staticClass:"md-layout course-mg-bottom"},[(_vm.courseLeft(index1, 0))?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('h4',{staticClass:"info-title",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.title.title,
                parent: _vm.courses.data[index1].title,
                path: `/body/courses/data[${index1}]/title`
              }])}}},[_vm._v(" "+_vm._s(course.title.title.text)+" ")]),_c('h4',{staticClass:"info-title",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.ages.ages,
                parent: _vm.courses.data[index1].ages,
                path: `/body/courses/data[${index1}]/ages`
              }])}}},[_vm._v(" "+_vm._s(course.ages.ages.text)+" ")]),_c('hr'),_c('div',{staticClass:"md-layout"},[_vm._l((course.info),function(info){return _c('div',{key:info.name,staticClass:"md-layout-item text-align-left md-small-size-100",class:info.size,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                  element: info,
                  parent: course.info,
                  path: `/body/courses/data[${index1}]/info`
                }])}}},[_c('span',{staticClass:"info-title info-text"},[_vm._v(" "+_vm._s(info.name)+" ")]),_c('span',{staticClass:"info-text info-title"},[_vm._v(" "+_vm._s(info.description)+" ")])])}),(course.lastinfo)?_c('div',{staticClass:"md-layout-item md-size-100 text-align-left"},[_c('span',{staticClass:"info-title info-text"},[_vm._v(_vm._s(course.lastinfo.name))]),_vm._v(" "),_c('span',{staticClass:"info-title info-text"},[_vm._v(_vm._s(course.lastinfo.description))])]):_vm._e()],2),_c('hr'),_c('p',{staticClass:"course-description",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.description.description,
                parent: course.description,
                path: `/body/courses/data[${index1}]/description`
              }])}}},[_c('span',{staticClass:"info-title"},[_vm._v("Описание: ")]),_vm._v(" "+_vm._s(course.description.description.text)+" ")]),(!_vm.laptop)?_c('md-button',{staticClass:"md-success",on:{"click":function($event){return _vm.$emit('subscribeOpen', course)}}},[_vm._v(" "+_vm._s(course.buttonText)+" ")]):_vm._e(),(!_vm.laptop && course.buttonProgram !== '')?_c('md-button',{staticClass:"md-success",attrs:{"href":course.buttonProgram,"target":"_blank"}},[_vm._v(" Программа занятий ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100",attrs:{"id":course.link},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [
              {
                element: course.images.images[0],
                parent: course.images.images,
                path: `/body/courses/data[${index1}]/images/images`
              },
              {
                element: course.videos.videos[0],
                parent: course.videos.videos,
                path: `/body/courses/data[${index1}]/videos/videos`
              },
            ])}}},[_c('md-card',[_c('carousel',{attrs:{"per-page":1,"loop":"","speed":700,"autoplay-timeout":course.time,"mouse-drag":true,"navigationEnabled":"","navigationNextLabel":"<i class='material-icons'>keyboard_arrow_right</i>","navigationPrevLabel":"<i class='material-icons'>keyboard_arrow_left</i>"}},[_vm._l((course.videos.videos),function(video,index3){return (video.helpers.show)?_c('slide',{key:index3+10,staticClass:"parent-cover"},[[_c('LazyYoutubeVideo',{attrs:{"src":video.link}})]],2):_vm._e()}),_vm._l((course.images.images),function(img,index2){return (img.helpers.show)?_c('slide',{key:index2,staticClass:"parent-cover"},[_c('img',{staticClass:"cover",attrs:{"src":img.link,"alt":`${course.title} ${index2}`}})]):_vm._e()})],2)],1),_c('br'),_c('br'),(_vm.laptop)?_c('md-button',{staticClass:"md-success",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.buttonText.buttonText,
                parent: course.buttonText,
                path: `/body/courses/data[${index1}]/buttonText`
              }])}}},[_vm._v(" "+_vm._s(course.buttonText.buttonText.text)+" ")]):_vm._e(),(_vm.laptop && course.buttonProgram !== '')?_c('md-button',{staticClass:"md-success m-left",attrs:{"href":course.buttonProgram,"target":"_blank"}},[_vm._v(" Программа занятий ")]):_vm._e()],1),(_vm.courseLeft(index1, 1))?_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('h4',{staticClass:"info-title",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.title.title,
                parent: _vm.courses.data[index1].title,
                path: `/body/courses/data[${index1}]/title`
              }])}}},[_vm._v(" "+_vm._s(course.title.title.text)+" ")]),_c('h4',{staticClass:"info-title",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.ages.ages,
                parent: _vm.courses.data[index1].ages,
                path: `/body/courses/data[${index1}]/ages`
              }])}}},[_vm._v(" "+_vm._s(course.ages.ages.text)+" ")]),_c('hr'),_c('div',{staticClass:"md-layout"},[_vm._l((course.info),function(info){return _c('div',{key:info.name,staticClass:"md-layout-item text-align-left md-small-size-100",class:info.size,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                  element: info,
                  parent: course.info,
                  path: `/body/courses/data[${index1}]/info`
                }])}}},[_c('span',{staticClass:"info-title info-text"},[_vm._v(" "+_vm._s(info.name)+" ")]),_c('span',{staticClass:"info-text info-title"},[_vm._v(" "+_vm._s(info.description)+" ")])])}),(course.lastinfo)?_c('div',{staticClass:"md-layout-item md-size-100 text-align-left"},[_c('span',{staticClass:"info-title info-text"},[_vm._v(_vm._s(course.lastinfo.name))]),_vm._v(" "),_c('span',{staticClass:"info-title info-text"},[_vm._v(_vm._s(course.lastinfo.description))])]):_vm._e()],2),_c('hr'),_c('p',{staticClass:"course-description",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: course.description.description,
                parent: course.description,
                path: `/body/courses/data[${index1}]/description`
              }])}}},[_c('span',{staticClass:"info-title"},[_vm._v("Описание: ")]),_vm._v(" "+_vm._s(course.description.description.text)+" ")]),(!_vm.laptop)?_c('md-button',{staticClass:"md-success",on:{"click":function($event){return _vm.$emit('subscribeOpen', course)}}},[_vm._v(" "+_vm._s(course.buttonText)+" ")]):_vm._e(),(!_vm.laptop && course.buttonProgram !== '')?_c('md-button',{staticClass:"md-success",attrs:{"href":course.buttonProgram,"target":"_blank"}},[_vm._v(" Программа занятий ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"md-layout"},_vm._l((course.additionally),function(additionally,additionallyIndex){return _c('div',{key:additionally.name.text,staticClass:"md-layout-item md-size-33 md-small-size-80 course-m-bottom login-cart-center",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('coursesClick', [{
                element: additionally,
                parent:  course.additionally[additionallyIndex],
                path: `/body/courses/data[${index1}]/additionally[${additionallyIndex}]`
              }])}}},[_c('login-card',{attrs:{"header-color":"green"}},[_c('h4',{staticClass:"price-title",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(additionally.name.text)+" ")]),_c('template',{slot:"inputs"},[_c('h4',[_vm._v(" "+_vm._s(additionally.price.text)+" ")])]),_c('md-button',{staticClass:"md-simple md-success md-lg",attrs:{"slot":"footer"},on:{"click":function($event){return _vm.$emit('openAdditionally', additionally)}},slot:"footer"},[_vm._v(" Подробнее ")])],2)],1)}),0)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }