<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-transparent md-absolute"
    :class="extraNavClasses"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start pointer" @click="scrollToElement('heading')">
        <img
          :src="clubLogo"
          alt="Клуб Генри"
          class="img-club-logo-size"
        />
        <img
          :src="clubNameWhite"
          alt="Клуб Генри"
          class="img-club-name-size"
          v-if="!clubNameColor && !isBlack"
        />
        <img
          :src="clubNameBlack"
          alt="Клуб Генри"
          class="img-club-name-size"
          v-else
        />
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper" 
          >
            <mobile-menu nav-mobile-section-start="false">
            </mobile-menu>
            <md-list v-if="$store.state.apiData != null">
              <md-list-item
                href="javascript:void(0)"
                v-for="headLink in $store.state.apiData.header.links"
                :key="headLink.text"
                @click="scrollToElement(headLink.target)"
              >
                <p class="header-text" :class="{'isBlackClass': isBlack }">{{ headLink.text }}</p>
              </md-list-item>

              <md-list-item
                v-for="headButton in $store.state.apiData.header.buttons"
                :key="headButton.text"
                v-show="headButton.helpers.show"
              >
                <md-button
                  :href="headButton.link"
                  class="md-success">
                  <p class="header-text" :class="{'isBlackClass': isBlack }">{{ headButton.text }}</p>
                </md-button>
              </md-list-item>

              <md-list-item
                v-for="headSocialLink in $store.state.apiData.header.socialLinks"
                :key="headSocialLink.text"
                :href="headSocialLink.link"
                v-show="headSocialLink.helpers.show"
                target="_blank"
                rel="external"
              >
                <i class="fab" :class="headSocialLink.icon"></i>
                <p class="hidden-lg header-text" :class="{'isBlackClass': isBlack }">{{ headSocialLink.text }}</p>
                <md-tooltip md-direction="bottom">{{ headSocialLink.tooltip }}</md-tooltip>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
export default {
  components: {
    MobileMenu
  },
  props: {
    clubNameBlack: {
      type: String,
      default: require("@/assets/img/club/Logo2.png")
    },
    clubLogo: {
      type: String,
      default: require("@/assets/img/club/Robot1.png")
    },
    clubNameWhite: {
      type: String,
      default: require("@/assets/img/club/Logo1.png")
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isBlack: true,
      moveToDown: "",
      extraNavClasses: "",
      toggledClass: false,
      clubNameColor: false
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["login", "landing", "profile"];
      return excludedRoutes.every(r => r !== this.$route.name);
    }
  },
  methods: {
    eventColor(){
      this.isBlack = false
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        (document.body.scrollTop || document.documentElement.scrollTop) + 300;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        this.clubNameColor = true;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          this.clubNameColor = false;
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement(element) {
      let element_id = document.getElementById(element);
      if (element_id) {
        element_id.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  },
  mounted() {
    this.$root.$on('eventColor', this.eventColor)
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.pswp__item {
  z-index: 102 !important;
}
.md-toolbar.md-absolute {
  z-index: 101 !important;
}
.header-text {
  font-size: 14px !important;
}
.img-club-name-size {
  max-width: 60%;
}
.img-club-logo-size {
  max-width: 17%;
  margin-right: 10px;
}
.isBlackClass{
  color: black !important;
}
</style>
