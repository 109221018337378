var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section text-center",attrs:{"id":"team"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.friends.title))]),_c('div',{staticClass:"md-layout"},_vm._l((_vm.friends.data),function(friend,index){return _c('div',{key:friend.text.name,staticClass:"image md-layout-item md-size-20 md-medium-size-33 md-small-size-100"},[_c('a',{staticClass:"team-player",attrs:{"href":friend.text.link,"target":"_blank"}},[_c('div',{staticClass:"md-card-plain"},[_c('div',{staticClass:"md-layout-item md-size-100 mx-auto"},[_c('img',{staticClass:"img-fluid",attrs:{"src":friend.image.headimage.link,"alt":friend.text.name},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('friendsClick', [
                    {
                      element: friend.text.text,
                      parent:  friend.text,
                      path: `/body/friends/data[${index}]/text`
                    },
                    {
                      element: friend.image.headimage,
                      parent: friend.image,
                      path: `/body/friends/data[${index}]/image/headimage/link`
                    }
                  ])}}})])])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }