<template>
  <div class="main main-raised z-index-up">
    <div class="section" id="about-us">
      <div class="container text-center">
        <h2 class="title">Фильм о будущем современных детей</h2>
        
        <div class="md-layout mt-150">
          <div class="md-layout-item text-center mt-150 hght">
              <LazyYoutubeVideo src="https://www.youtube.com/embed/EV6mXsCjSZg" aspectRatio="16:9"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style>
.y-video__media {
  height: 100% !important;
}
.hght {
  max-height: 30%;
}
.section > .container > .title {
  margin-bottom: 0 !important;
}
.text-bold {
  font-weight: 700;
}
.padding-off {
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0px !important;
}
.z-index-up {
  z-index: 100 !important;
}
.mt-150 {
  margin-top: 30px;
}
.max-size {
  width: 100%;
}
.text-formating {
  text-align: left;
}
.text-formating > h4 {
  font-size: 1.0rem;
  text-indent: 40px;
}
</style>