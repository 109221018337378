<template>
  <div class="main main-raised z-index-up">
    <div class="section" id="about-us">
      <div class="container text-center">
        <div v-for="title in sectionData.titles" :key="title.text">
          <h2 
            class="title"
          >
            {{ title.text }}
            </h2>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100 text-formating">
            <h2 class="fix-size-text-g">
              <span class="green-text">Генри клуб</span>
                – это развивающий проект по робототехнике, где дети изучают основы конструирования, 3D-моделирования, программирования и 3D-печати на современных образовательных решениях от Lego Education и Arduino
            </h2>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100 text-formating">
            <md-card>

              <md-card-content>
                <h3 class="upper-text">
                  В работе мы сочетаем инженерное дело и психологию. У нас маленькие группы до 4 человек и особое внимание к личности ребенка. Программа занятий построена этапами, основная задача которой, дать
                  <br>в игровой и понятной форме базовый инструментарий для успешного старта в будущих профессиях: инженер, робототехник, 3D дизайнер, программист, изобретатель, технологический предприниматель
                </h3> 
              </md-card-content>
            </md-card>
          </div>
        </div>

        <div class="md-layout mt-10">
          <div class="md-layout-item md-size-100 md-xsmall-size-100 md-layout">
            <div class="md-layout-item md-size-25 md-xsmall-size-50 text-center upper">
              <h3>Работаем</h3>
              <hr>
              <p class="upper-text green-text">с 2019 года</p>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-50 text-center upper">
              <h3>Сельмаш</h3>
              <hr>
              <p class="upper-text green-text">Западный</p>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-50 text-center upper">
              <h3>Провели более</h3>
              <hr>
              <p class="upper-text green-text">4800 +</p>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-50 text-center upper">
              <h3>Лучший детский</h3>
              <hr>
              <p class="upper-text green-text">проект 2023 г.</p>
            </div>
          </div>
        </div>
        <div class="md-layout mt-10">
          <div class="md-layout-item md-size-100">
            <carousel
              :per-page="4"
              loop
              :speed="700"
              :mouse-drag="true"
              navigationEnabled
              navigationNextLabel="<i class='material-icons dark-text'>keyboard_arrow_right</i>"
              navigationPrevLabel="<i class='material-icons dark-text'>keyboard_arrow_left</i>"
            >
              <slide
                v-for="(certificate, index) in certificates"
                :key="index"
              >
                <div class="p-10">
                  <img :src="certificate.image.headimage.link" :alt="certificate.text.text.name" class="cover md-elevation-3" />
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['sectionData', 'certificates']
}
</script>
<style>
.p-10{
  padding: 20px;
}
.mt-10{
  margin-top: 70px;
}
.upper-text{
  font-size: 1.5em;
}
.fix-size-text-g{
  font-size: 1.8em !important;
  word-spacing: 9px;
}
.section > .container > .title {
  margin-bottom: 0 !important;
}
.text-bold {
  font-weight: 700;
}
.padding-off {
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0px !important;
}
.z-index-up {
  z-index: 100 !important;
}
.mt-150 {
  margin-top: 30px;
}
.max-size {
  width: 100%;
}
.text-formating {
  text-align: left;
}
.text-formating > h4 {
  font-size: 1.1rem;
  text-indent: 40px;
}
.green-text {
  color: #4caf50;
}
</style>