var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main-raised"},[_c('div',{staticClass:"section text-center",attrs:{"id":"price"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.price.title.title.text))]),_c('div',{staticClass:"team"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-80 course-m-bottom login-cart-center"},[_c('login-card',[_c('template',{slot:"inputs"},[_c('h3',{staticClass:"green-color",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('priceClick', [
                    {
                      element: _vm.price.leftColumn.title.title,
                      parent:  _vm.price.leftColumn.title,
                      path: `/body/price/leftColumn/title`
                    }
                  ])}}},[_vm._v(" "+_vm._s(_vm.price.leftColumn.title.title.text)+" ")]),_vm._l((_vm.price.leftColumn.descriptions),function(description,index){return _c('h4',{key:description.title.text,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('priceClick', [
                    {
                      element: description.title,
                      parent:  _vm.price.leftColumn.descriptions[index],
                      path: `/body/price/leftColumn/descriptions[${index}]`
                    }
                  ])}}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(description.title.text))]),_c('span',{domProps:{"innerHTML":_vm._s(description.description.text)}})])})],2)],2)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-80 course-m-bottom login-cart-center"},[_c('login-card',[_c('template',{slot:"inputs"},[_c('h3',{staticClass:"green-color",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('priceClick', [
                    {
                      element: _vm.price.rightColumn.title.title,
                      parent:  _vm.price.rightColumn.title,
                      path: `/body/price/rightColumn/title`
                    }
                  ])}}},[_vm._v(" "+_vm._s(_vm.price.rightColumn.title.title.text)+" ")]),_c('h3',{staticClass:"upper-text-size",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('priceClick', [
                    {
                      element: _vm.price.rightColumn.description.description,
                      parent:  _vm.price.rightColumn.description,
                      path: `/body/price/rightColumn/description`
                    }
                  ])}}},[_vm._v(" "+_vm._s(_vm.price.rightColumn.description.description.text)+" ")])]),_c('md-button',{staticClass:"md-success md-lg",attrs:{"slot":"footer"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('priceClick', [
                  {
                    element: _vm.price.rightColumn.link.link,
                    parent:  _vm.price.rightColumn.link,
                    path: `/body/price/rightColumn/link`
                  }
                ])}},slot:"footer"},[_vm._v(" Открыть прайс-лист ")])],2)],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }