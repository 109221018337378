<template>
  <div>
    <div v-if="$store.state.loading || $store.state.apiData == null" class="loaded"> <!---->
      <img src="./assets/img/club/Robot1.png" alt="iamge" class="preload-image">
    </div>
    <div :class="{ 'nav-open': NavbarStore.showNavbar }">
      <router-view name="header" @navHeaderButtonClick="navHeaderButton"/>
      <div>
        <router-view @navBodyClick="navBody"/>
      </div>
      <router-view 
        v-if="$store.state.preLoading"
        name="footer" />
    </div>

    <modal v-if="editModal" @close="editModal = false">
      <template slot="header">
        <div 
          v-for="(editedElem, index) in editedElement"
          :key="index"
        >
          <h4 class="modal-title text-center">
            ({{editedElem.path}})
          </h4>
        </div>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="editModal = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <div class="fix-size ">
          <div 
            v-for="(editedElem, index) in editedElement"
            :key="index"
          >
            <div class="md-layout">
              <div class="md-layout-item md-size-100 bording" 
                v-for="(element, index) in editedElem.parent" 
                :key="index"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-50"
                    :class="{'md-size-80': Object.keys(element).length == 2}"
                    v-for="(elementField, index) in element"
                    :key="index"
                    v-show="index != 'helpers'"
                  >
                    <md-field 
                      v-show="index != 'helpers'"
                      v-if="(typeof elementField) != 'object' && element.helpers"
                    >
                      <label v-if="element.helpers && element.helpers.load">
                        {{element.helpers.load.type}}: {{element.helpers.load.sizeX}}x{{element.helpers.load.sizeY}}
                      </label>
                      <md-file 
                        v-if="element.helpers && element.helpers.load"
                        :accept="element.helpers.load.type"
                        @md-change="onFileUpload($event, editedElem, element.helpers.load.type)"
                      />
                      <md-input
                        v-else
                        v-model="element[index]"
                        type="text"
                        :placeholder="index"
                        :disabled="element.helpers && !element.helpers.show"
                      ></md-input>
                      <span class="md-helper-text">{{index}}</span>
                    </md-field>
                    <div v-else>
                      <md-field 
                        v-show="index != 'helpers'"
                        :key="element[index].text"
                      >
                        <label v-if="element[index].helpers && element[index].helpers.load">
                          {{element[index].helpers.load.type}}: {{element[index].helpers.load.sizeX}}x{{element[index].helpers.load.sizeY}}
                        </label>
                        <md-file 
                          v-if="element[index].helpers && element[index].helpers.load"
                          :accept="element[index].helpers.load.type"
                          @md-change="onFileUpload($event, editedElem, element[index].helpers.load.type)"
                        />
                        <md-input
                          v-else
                          v-model="element[index].text"
                          type="text"
                          :placeholder="element[index].text"
                          :disabled="element[index].helpers && !element[index].helpers.show"
                        ></md-input>
                        <span class="md-helper-text">{{index}}</span>
                      </md-field>
                        <md-button 
                          class="md-icon-button md-dense md-raised md-accent"
                          @click="delElement(element[index])"
                          v-if="element[index].helpers"
                        >
                          <md-icon v-if="!element[index].helpers.show">visibility</md-icon>
                          <md-icon v-else>visibility_off</md-icon>
                        </md-button>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-10">
                    <md-button 
                      class="md-icon-button md-dense md-raised md-accent"
                      @click="delElement(element)"
                      v-if="element.helpers"
                    >
                      <md-icon v-if="!element.helpers.show">visibility</md-icon>
                      <md-icon v-else>visibility_off</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout md-fixed-header">
              <div class="md-layout-item">
                <md-button
                  class="md-warning modal-button"
                  v-if="editedElem.element.helpers && editedElem.element.helpers.multy"
                  @click="addElement(editedElem)"
                >
                  Добавить
                </md-button>
              </div>
              <div class="md-layout-item">
                <md-button
                  v-if="editedElem.element.helpers && !editedElem.element.helpers.load"
                  class="md-success modal-button"
                  @click="save(editedElem)">
                  Сохранить
                </md-button>
                <md-button
                  v-if="editedElem.element.helpers && editedElem.element.helpers.load"
                  class="md-success modal-button"
                  @click="pushAsset(editedElem)">
                  Загрузить
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Modal } from "@/components"
import { API } from "./api"

export default {
  components: {
    Modal,
  },
  data() {
    return {
      editedElement: null,
      editModal: false,
      isBlack: true
    }
  },
  async created() {
    this.$root.$on('eventColor', this.eventColor)
    await API.get('')
    .then(res => {
      //console.log(res.data)
      this.$store.commit('dataLoaded', res.data)
    })
    .catch(err => {
      //console.log(err)
    })
  },
  methods: {
    eventColor(){
      this.isBlack = false
    },
    onFileUpload(event, data, type){
      if(type == 'image/*') {
        //console.log()
        data.parent.headimage.helpers.load.buffer = event[0]
      }
      if(type == 'video/*') {
        data.parent.videos.helpers.load.buffer = event[0]
      }
      //console.log(event)
    },
    async pushAsset(data){
      let bodyFormData = new FormData()
      bodyFormData.append('path', data.path)
      //console.log(data.element.helpers.load)
      bodyFormData.append('helpers', {sizeX: data.element.helpers.load.sizeX, sizeY: data.element.helpers.load.sizeY})
      if(data.parent.videos && data.parent.videos.helpers.load.type == 'video/*' && data.parent.videos.helpers.load.buffer != null)
      {
        bodyFormData.append('avatar', data.parent.videos.helpers.load.buffer)
      }
      else if(data.parent.headimage && data.parent.headimage.helpers.load.type == 'image/*' && data.parent.headimage.helpers.load.buffer != null)
      {
        bodyFormData.append('avatar', data.parent.headimage.helpers.load.buffer)
      }else {
        console.log('No image or video')
        return
      }

      await API.post('upload', 
        bodyFormData,
        {
          "Content-Type": "multipart/form-data",
        },
        {
          withCredentials: true
        })
      .then(res => {
        //console.log(res.data)
        location.reload()
      })
      .catch(err => {
        //console.log(err)
      })

    },
    navBody(data){
      this.editedElement = JSON.parse(JSON.stringify(data))
      this.editModal = true
      //console.log(this.editedElement)
    },
    navHeaderButton(data) {
      this.editedElement = JSON.parse(JSON.stringify(data))
      this.editModal = true
      //console.log(this.editedElement)
    },
    async save(data) {
      //console.log(data)
      let body = {
        path: data.path,
        data: data.parent
      }
      await API.post('edit', body,
      {
        withCredentials: true
      })
      .then(res => {
        //console.log(res.data)
        this.$store.commit('dataLoaded', res.data)
      })
      .catch(err => {
        //console.log(err)
      })
    },
    addElement(data) {
      data.parent.push(JSON.parse(JSON.stringify(data.parent[0])))
      let last = data.parent[data.parent.length-1]
      Object.keys(last).forEach(element => {
        if(element != 'helpers'){
          last[element] = null
        }else{
          last[element].show = true
        }
      })
    },
    delElement(data) {
      data.helpers.show = !data.helpers.show
      //console.log(data)
    }
  }

}
</script>

<style>
*{
  font-family: 'Montserrat',Arial,sans-serif;
}
.fix-size {
  min-height: 150px;
  max-height: 500px;
  overflow-y:auto;
  overflow-x:hidden ;
}
.parent-cover .y-video .y-video__inner .y-video__media {
  height: 100% !important;
}
.md-tabs-navigation {
  padding: 0 !important;
  margin: 0 !important;
}
.loaded {
  z-index: 999999;
  position: absolute;
  left: 0;
  right: 0; 
  bottom: 0; 
  top:0;
  background-color: #fafafa;
}
.preload-image {
  max-width: 125px;
  width: 10%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: 3s linear 0s infinite alternate slidein;
}
.bording {
  border: 1px solid silver;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: none;
}
@keyframes slidein {
  from {
    left: 70%;
  }

  to {
    left: 30%;
  }
}
</style>
