<template>
  <div class="wrapper" id="heading">
    <div class="md-layout md-alignment-center-center">
      <div 
        class="md-layout-item md-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20 md-small-size-66 top-center"
      >
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-card>
            <md-card-content>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-100">
                  <md-field :class="getValidationClass('login')">
                    <label for="login">Login</label>
                    <md-input name="login" id="login" autocomplete="login" v-model="form.login" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.login.required">The login is required</span>
                    <span class="md-error" v-else-if="!$v.form.login.minlength">Invalid login</span>
                  </md-field>
                </div>

                <div class="md-layout-item md-size-100">
                  <md-field :class="getValidationClass('password')">
                    <label for="password">Password</label>
                    <md-input type="password" name="password" id="password" autocomplete="password" v-model="form.password" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.password.required">The password required</span>
                    <span class="md-error" v-else-if="!$v.form.password.minlength">Invalid password</span>
                  </md-field>
                </div>

                <div v-if="step == 1" class="md-layout-item md-size-100">
                  <md-field :class="getValidationClass('checkpassword')">
                    <label for="checkpassword">Check Password</label>
                    <md-input type="password" name="checkpassword" id="checkpassword" autocomplete="checkpassword" v-model="form.checkpassword" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.checkpassword.required">The check password is required</span>
                    <span class="md-error" v-else-if="!$v.form.checkpassword.minlength">Invalid check password</span>
                  </md-field>
                </div>
              </div>
            </md-card-content>

            <md-progress-bar md-mode="indeterminate" v-if="sending" />

            <md-card-actions class="md-layout">
              <md-button type="submit" class="md-layout-item md-primary" :disabled="sending">Login</md-button>
            </md-card-actions>
          </md-card>
          <md-snackbar :md-active.sync="userSaved">{{serverMessage}}</md-snackbar>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { API } from "../../api"
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

export default {
  name: 'LoginPage',
  mixins: [validationMixin],
  data: () => ({
    form: {
      login: null,
      password: null,
      checkpassword: 'pass'
    },
    userSaved: false,
    sending: false,
    step: 0,
    lastUser: null,
    serverMessage: null
  }),
  validations: {
    form: {
      login: {
        required,
        minLength: minLength(3)
      },
      password: {
        required,
        minLength: minLength(3)
      },
      checkpassword: {
        required,
        minLength: minLength(0)
      }
    }
  },
  methods: {
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
      this.form.login = null
      this.form.password = null
      this.form.checkpassword = null
    },
    async checkCookie(){
    },
    async saveUser () {
      this.sending = true

      // Instead of this timeout, here you can call your API
      if(this.step == 0)
      {
        await API.post("auth", {login: this.form.login, password: this.form.password})
        .then((res) => {
          console.log(res)
          this.serverMessage = res.data.result
          this.userSaved = true
          this.sending = false
          this.step = 1
          //this.clearForm()
        })
        .catch((err) => {
          console.log(err)
          this.serverMessage = err
          this.userSaved = true
          this.sending = false
        })
      } else if(this.step == 1){
        await API.post("auth/check", 
        {
          login: this.form.login,
          password: this.form.password,
          generatedPassword: this.form.checkpassword
        },
        {
          withCredentials: true
        })
        .then((res) => {
          console.log(res)
          if(res.data.success){
            this.serverMessage = 'Success Login!'
            this.$store.commit('accessUpdate', $cookies.get('accessKey'))
            this.$router.push('/admin');
          }
          this.userSaved = true
          this.sending = false
          //this.clearForm()
        })
        .catch((err) => {
          console.log(err)
        })
      }
      /*window.setTimeout(() => {
        this.lastUser = `${this.form.login} ${this.form.password}`
        this.userSaved = true
        this.sending = false
        this.step = 1
        this.clearForm()
      }, 1500)*/
    },
    validateUser () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.saveUser()
      }
    }
  },
  async mounted() {
    this.step = 0
    if($cookies.get('accessKey')){
      await API.get("/auth/checkcookie",
      {
        withCredentials: true
      })
      .then((res) => {
        console.log(res)
        if(res.data.success){
          this.$store.commit('accessUpdate', $cookies.get('accessKey'))
          this.$router.push('/admin')
        } else {
          this.$router.push('/login')
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
    document.onreadystatechange = () => { 
      if (document.readyState == "complete") { 
        this.$store.commit('loaded')
        this.$store.commit('preLoaded')
      } 
    }
    this.laptop = window.innerWidth > 1000
    if(window.innerWidth < 1000) this.showCall = true
  },
}
</script>
<style scoped>
.top-center{
  margin-top: 15%;
}
.hidden{
  overflow:hidden;
}
</style>