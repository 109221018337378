<template>
  <div class="main main-raised">
    <div class="section" id="about-us">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <div v-for="title in sectionData.titles" :key="title.text">
              <h2 
                class="title text-center"
              >
                {{ title.text }}
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-xsmall-size-100 md-layout">
            <div v-for="upper in sectionData.uppers" :key="upper.text" 
              class="md-layout-item md-size-25 md-xsmall-size-100 text-center upper">
              <h4 class="upper-name" v-html="upper.name"></h4>
              <hr>
              <h5 class="upper-text" v-html="upper.text"></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['sectionData']
}
</script>
<style scoped>
.text-align-center {
  text-align: center;
}
.upper {
  margin-top: 70px;
}
.upper-name {
  color: #42b983;
  font-weight: 400;
}
.upper-text {
  font-size: 1.1em;
}
</style>