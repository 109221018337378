<template>
  <div>
    <button class="pushable" @click="$emit('clicked')" :style="stylePush">
      <span class="front" :style="styleFront">
        {{text}}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: "SVButton",
  props: {
    text: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ""
    }
  },
  data(){
    return{
      stylePush:"",
      styleFront: ""
    }
  },
  created() {
    if(this.color == 'purple'){
      this.stylePush = "background: hsl(265deg, 98%, 55%);"
      this.styleFront = "background: hsl(265deg, 98%, 67%);"
    }else if(this.color == 'green'){
      this.stylePush = "background: hsl(145deg, 63%, 30%);"
      this.styleFront = "background: hsl(150deg, 63%, 49%);"
    }
  }
};
</script>

<style scoped>
.front {
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.pushable:hover .front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}
.pushable:disabled .front,
.pushable[disabled] .front{
  transition: none !important;
}
.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.pushable {

  border: none;
  border-radius: 12px;
  padding: 0;
  cursor: pointer;
}
.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;

  color: white;
  transform: translateY(-4px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}
@media (max-width: 960px) {
  .front {
    font-size: 1rem;
  }
  .pushable{
    margin-top: 20px;
    width: 100%;
  }
}
</style>