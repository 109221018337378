<template>
  <div v-if="showCallWidget" type="button" class="callback-bt" @click="$emit('ClickCallWidget')">
    <div class="text-call">
      <i class="fa fa-phone"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: ['showCallWidget']
}
</script>
<style scoped>

.callback-bt {
  background:#4caf50;
  border: 2px solid #4caf50;
  border-radius: 50%;
  box-shadow:0 8px 10px rgba(89, 253, 56, 0.3);
  cursor: pointer;
  height: 68px;
  text-align: center;
  width: 68px;
  position: fixed;
  right: 8%;
  bottom: 18%;
  z-index: 999;
  transition:.3s;
  -webkit-animation:hoverWave linear 1s infinite;
  animation:hoverWave linear 1s infinite;
}

.callback-bt .text-call{
  height: 68px;
  width: 68px;        
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.callback-bt .text-call span {
  text-align: center;
  color:#38a3fd;
  opacity: 0;
  font-size: 0;
  position:absolute;
  right: 4px;
  top: 22px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: opacity .3s linear;
  font-family: 'montserrat', Arial, Helvetica, sans-serif;
}
.callback-bt i {
  color:#fff;
  font-size:34px;
  transition:.3s;
  line-height: 66px;transition: .5s ease-in-out;
}

.callback-bt i  {
  animation: 1200ms ease 0s normal none 1 running shake;
  animation-iteration-count: infinite;
  -webkit-animation: 1200ms ease 0s normal none 1 running shake;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes hoverWave {
  0% {
    box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 0 rgba(89, 253, 56,0.2),0 0 0 0 rgba(89, 253, 56,0.2)
  }
  40% {
    box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 15px rgba(89, 253, 56,0.2),0 0 0 0 rgba(89, 253, 56,0.2)
  }
80% {
        box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 30px rgba(89, 253, 56,0),0 0 0 26.7px rgba(89, 253, 56,0.067)
}
100% {
        box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 30px rgba(89, 253, 56,0),0 0 0 40px rgba(89, 253, 56,0.0)
}
}
@keyframes hoverWave {
        0% {
        box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 0 rgba(89, 253, 56,0.2),0 0 0 0 rgba(89, 253, 56,0.2)
}
40% {
        box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 15px rgba(89, 253, 56,0.2),0 0 0 0 rgba(89, 253, 56,0.2)
}
80% {
        box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 30px rgba(89, 253, 56,0),0 0 0 26.7px rgba(89, 253, 56,0.067)
}
100% {
        box-shadow:0 8px 10px rgba(89, 253, 56,0.3),0 0 0 30px rgba(89, 253, 56,0),0 0 0 40px rgba(89, 253, 56,0.0)
}
}

/* animations icon */

@keyframes shake {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}

@-webkit-keyframes shake {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}
</style>